<template>
  <div class="container">
    <h2>Edit {{this.section == "News"?"News":"Event" }} Item</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteNewsFeedItems()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
 <div class="col-md-12">
        <label class for="Link">Content</label>
        <!-- <textarea
          class="form-control"
          id="Content"
          name="Content"
          v-model="editingNewsFeedItems.Content"
          placeholder=" "
          tabindex="0"
          style="height:280px"
          type="text"
        ></textarea> -->

         <VueEditor  v-model="editingNewsFeedItems.Content"/>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class for="Title">Title</label>
        <input
          class="form-control"
          id="Title"
          name="Title"
          v-model="editingNewsFeedItems.Title"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Blurb">Blurb</label>
        <textarea
          class="form-control"
          id="Blurb"
          name="Blurb"
          v-model="editingNewsFeedItems.Blurb"
          placeholder=" "
          tabindex="0"
          type="text"
             style="height:280px"
        ></textarea>

      </div>

     
      <div class="col-md-4">
        <label class for="Slug">Slug</label>
        <input
          class="form-control"
          id="Slug"
          name="Slug"
          v-model="editingNewsFeedItems.Slug"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ExternalLink">External Link</label>
        <input
          class="form-control"
          id="ExternalLink"
          name="ExternalLink"
          v-model="editingNewsFeedItems.ExternalLink"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="DisplayOrder">Display Order</label>
        <input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="editingNewsFeedItems.DisplayOrder"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Published">Published</label>
        <input
          class="form-control"
          id="Published"
          name="Published"
          v-model="editingNewsFeedItems.Published"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class="" for="Active">Active</label>
        <br />
        <b-form-checkbox
          v-model="editingNewsFeedItems.Active"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>

      <div class="col-md-4">
        <ImageUploader :width="1366" :height="564" label="Article Image" v-model="editingNewsFeedItems.Image" />
      </div>
      <div class="col-md-4">
        <ImageUploader label="Mobile Image" v-model="editingNewsFeedItems.MobileImage" />
      </div>
       <div class="col-md-4">
        <ImageUploader :width="360" :height="285" label="Thumb Image" v-model="editingNewsFeedItems.ThumbImage" />
      </div>

    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import { VueEditor } from "vue2-editor";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditNewsFeedItems",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    ImageUploader,VueEditor
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingNewsFeedItems));
    this.ImageBytes = this.editingNewsFeedItems.Image;
    console.log("editNewsFeedItem", this.section);
  },

  props: ["editingNewsFeedItems", "section"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditNewsFeedItems");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingNewsFeedItems));

      this.editingNewsFeedItems.LastUpdated = new Date();
      this.editingNewsFeedItems.NewsFeedItemTypeId =
        this.section == "News" ? 1 : 2;

      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("editNewsFeedItemsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("editNewsFeedItem", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteNewsFeedItems: function () {
      var payload = {
        data: this.editingNewsFeedItems,
        success: (response) => {
          this.$emit("editNewsFeedItemsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteNewsFeedItem", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
